import React, { useRef, useState } from "react"
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverlay,
  DraggableAttributes,
  DragStartEvent,
  UniqueIdentifier,
} from "@dnd-kit/core"
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities"
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable"
import { CSS, Transform } from "@dnd-kit/utilities"
import {
  Icon,
  Media,
  UnstyledButton,
  Label,
  TextArea,
  Input,
} from "@opensea/ui-kit"
import styled from "styled-components"
import { useDebouncedCallback } from "use-debounce"
import AssetSearchSortDropdown from "@/components/search/assets/AssetSearchSortDropdown.react"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { Gallery } from "@/design-system/Gallery"
import { calculateItemWidth } from "@/design-system/Gallery/calculateItemWidth"
import { Image } from "@/design-system/Image"
import { Modal, ModalProps } from "@/design-system/Modal"
import { SearchInput } from "@/design-system/SearchInput"
import { useTranslate } from "@/hooks/useTranslate"
import {
  trackCloseNewFeaturedShelf,
  trackDoneOnEditFeaturedShelf,
  trackDoneOnNewFeaturedShelf,
  trackFirstNextOnEditFeaturedShelf,
  trackFirstNextOnNewFeaturedShelf,
  trackSecondNextOnEditFeaturedShelf,
  trackSecondNextOnNewFeaturedShelf,
} from "@/lib/analytics/events/featuredShelfEvents"
import { SearchSortBy } from "@/lib/graphql/__generated__/FeaturedAddEditModalControllerQuery.graphql"
import { FEATURED_SHELF_SORTS } from "../utils/constants"
import { FeaturedAddEditModalItem } from "./FeaturedAddEditModalComponents.react"
import { QueriedFeaturedAsset } from "./FeaturedAddEditModalController.react"

export const FEATURED_ITEM_LIMIT = 10

type Tab = "created" | "collected"
export type Page = "choose_items" | "review_and_reorder" | "edit_details"

export type FeaturedAddEditModalState = {
  id: string | null
  tabs: Tab[]
  selectedTab: Tab
  shelfDisplayOrder: number
  order: QueriedFeaturedAsset[]
  initialOrder: string[]
  sort: { sortAscending: boolean; sortBy: SearchSortBy }
  title: string
  description: string | null
  search: string
  debouncedSearch: string
  hydrated: boolean
}

type FeaturedItemProps = {
  isDragging?: boolean
  index: number
  item: FeaturedAddEditModalState["order"][number]
  visible: boolean
  width: number
  onRemove: () => void
}
type SortableItemProps = {
  sortable?: {
    attributes: DraggableAttributes
    listeners: SyntheticListenerMap | undefined
    setNodeRef: (node: HTMLElement | null) => void
    transform: Transform | null
    transition: string | undefined
  }
}

function FeaturedItem({
  isDragging,
  index,
  item,
  width,
  onRemove,
  sortable,
  visible,
}: FeaturedItemProps & SortableItemProps) {
  const element = (
    <FeaturedAddEditModalItem
      asset={item}
      isDragging={isDragging ?? false}
      order={index}
      reviewMode
      shelfIsFull={false}
      visible={visible}
      width={width}
      onClick={onRemove}
    />
  )
  return sortable ? (
    <Container
      ref={sortable.setNodeRef}
      transform={CSS.Transform.toString(sortable.transform)}
      transition={sortable.transition}
      {...sortable.attributes}
      {...sortable.listeners}
    >
      {element}
    </Container>
  ) : (
    element
  )
}

function SortableFeaturedItem({
  isDragging,
  index,
  item,
  visible,
  width,
  onRemove,
}: FeaturedItemProps) {
  const sortable = useSortable({ id: item.relayId })

  return (
    <FeaturedItem
      index={index}
      isDragging={isDragging ?? sortable.isDragging}
      item={item}
      sortable={sortable}
      visible={visible}
      width={width}
      onRemove={onRemove}
    />
  )
}

export function FeaturedAddEditModal({
  modalPage,
  modalState,
  setModalPage,
  setModalState,
  onCancel,
  onFinish,
  children,
}: {
  modalPage: Page
  modalState: FeaturedAddEditModalState
  setModalPage: (page: Page) => void
  setModalState: (state: Partial<FeaturedAddEditModalState>) => void
  onCancel: () => void
  onFinish: (state: FeaturedAddEditModalState) => void
  children: React.ReactChild
}) {
  const t = useTranslate("phoenix")
  const sortableAreaRef = useRef<HTMLDivElement>(null)

  // Debounce the search state copy that triggers the relay refetch
  const debouncedSearchCallback = useDebouncedCallback((search: string) => {
    setModalState({ debouncedSearch: search })
  }, 1000)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const [activeRelayId, setActiveRelayId] = useState<UniqueIdentifier | null>(
    null,
  )

  function handleDragStart(event: DragStartEvent) {
    const { active } = event
    setActiveRelayId(active.id)
  }

  function handleDragEnd(event: DragEndEvent) {
    setActiveRelayId(null)
    const { active, over } = event

    if (active.id !== over?.id) {
      const oldIndex = modalState.order.findIndex(x => x.relayId === active.id)
      const newIndex = modalState.order.findIndex(x => x.relayId === over?.id)

      // This gets called on remove as well as on drag events. On remove, active will be an element
      // that is no longer in modalState.order so oldIndex will be -1 in that case.
      // If over is null then newIndex will be -1 and we also shouldn't do anything in that case.
      // That happens to only occur when we delete the last item from the array which also happens
      // have oldIndex equal to -1. So it is sufficient to check oldIndex being less than zero to
      // know if we are in a real drag event or a remove.
      if (oldIndex < 0) {
        return
      }
      setModalState({ order: arrayMove(modalState.order, oldIndex, newIndex) })
    }
  }

  const activeItem = modalState.order.find(x => x.relayId === activeRelayId)

  const modalProps: ModalProps = {
    closeOnOverlayClick: false,
    isOpen: true,
    position: "centered",
    onClose: () => {
      if (modalState.initialOrder.length === 0) {
        trackCloseNewFeaturedShelf()
      }
      onCancel()
    },
  }

  if (!modalState.order.length && modalPage === "review_and_reorder") {
    setModalPage("choose_items")
  }

  const shelfIsFull = modalState.order.length >= FEATURED_ITEM_LIMIT
  const modalTitle =
    modalPage === "choose_items" && !shelfIsFull
      ? t(
          "featured.chooseItemsTitle",
          {
            "0": "Choose up to {{count}} items to feature",
            one: "Choose up to {{count}} item to feature",
            other: "Choose up to {{count}} items to feature",
          },
          { count: FEATURED_ITEM_LIMIT },
        )
      : modalPage === "choose_items"
      ? t("featured.chooseItemsTitleShelfIsFull", "Item limit reached")
      : modalPage === "review_and_reorder"
      ? t("featured.reviewAndReorderTitle", "Review and reorder items")
      : t("featured.editDetails.title", "Edit details")

  const modalContent = (
    <>
      <Modal.Header>
        <Modal.Header.Title>{modalTitle}</Modal.Header.Title>
      </Modal.Header>
      <Modal.Body
        className="w-full"
        height="100%"
        padding="16px"
        paddingBottom="0"
      >
        {/* <Tabs tabs=""></Tabs> TODO: fragmentation? Changing design?*/}
        {modalPage === "choose_items" && (
          <>
            <Flex
              justifyContent="space-between"
              marginBottom="16px"
              marginX="3px"
              style={{ gap: 8 }}
            >
              <Block flexGrow={1}>
                <SearchInput
                  placeholder={t(
                    "featured.searchPlaceholder",
                    "Search collected items",
                  )}
                  value={modalState.search}
                  onChange={e => {
                    setModalState({ search: e.target.value })
                    debouncedSearchCallback(e.target.value)
                  }}
                />
              </Block>
              <Block width="240px">
                <StyledAssetSearchDropdown
                  searchState={modalState.sort}
                  setSort={sort => setModalState({ sort })}
                  sortOptions={FEATURED_SHELF_SORTS}
                />
              </Block>
            </Flex>
            {children}
          </>
        )}
        {modalPage === "review_and_reorder" && (
          <DndContext
            collisionDetection={closestCenter}
            sensors={sensors}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
          >
            <SortableContext
              items={modalState.order.map(item => item.relayId)}
              strategy={rectSortingStrategy}
            >
              <Block className="w-full" height="100%" ref={sortableAreaRef}>
                <Gallery
                  getKey={asset => asset.relayId}
                  gridGap={16}
                  itemHeightEstimate={300}
                  itemMinWidth={200}
                  items={modalState.order}
                  renderItem={item => (
                    <SortableFeaturedItem
                      index={item.index}
                      isDragging={false}
                      item={item.data}
                      key={item.data.relayId}
                      visible={
                        !activeItem || activeItem.relayId !== item.data.relayId
                      }
                      width={item.width}
                      onRemove={() =>
                        setModalState({
                          order: [
                            ...modalState.order.slice(0, item.index),
                            ...modalState.order.slice(item.index + 1),
                          ],
                        })
                      }
                    />
                  )}
                  variant="flex-wrap"
                />
              </Block>
            </SortableContext>
            <DragOverlay>
              {activeItem && (
                <FeaturedItem
                  index={11}
                  isDragging
                  item={activeItem}
                  visible
                  width={
                    calculateItemWidth({
                      gridGap: 16,
                      itemMinWidth: 200,
                      width: sortableAreaRef.current?.clientWidth || 0,
                      variant: "flex-wrap",
                    }).itemWidth
                  }
                  onRemove={() => undefined}
                />
              )}
            </DragOverlay>
          </DndContext>
        )}
        {modalPage === "edit_details" && (
          <>
            <Flex marginBottom="8px">
              <Label size="small">
                {t("featured.editDetails.titleLabel", "Title")}
              </Label>
            </Flex>
            <Input
              aria-label={t("featured.editDetails.titleLabel", "Title")}
              maxLength={50}
              placeholder={t(
                "featured.editDetails.titlePlaceholder",
                "My Featured Items",
              )}
              value={modalState.title}
              onChange={e => setModalState({ title: e.target.value })}
            />
            <br />
            <Flex marginBottom="8px">
              <Label size="small">
                {t(
                  "featured.editDetails.descriptionOptionalLabel",
                  "Description (optional)",
                )}
              </Label>
            </Flex>
            <TextArea
              aria-label={t(
                "featured.editDetails.descriptionLabel",
                "Description",
              )}
              maxLength={200}
              placeholder={t(
                "featured.editDetails.descriptionPlaceholder",
                "Add a description",
              )}
              value={modalState.description || undefined}
              onChange={e => setModalState({ description: e.target.value })}
            />
            <br />
            <Flex marginBottom="16px" marginRight="-8px">
              {modalState.order.slice(0, 7).map((asset, index) => (
                <Block
                  className="relative rounded-lg"
                  height="70px"
                  key={asset.relayId}
                  marginRight="8px"
                  overflow="hidden"
                  width="70px"
                >
                  <Image
                    alt={asset.name || ""}
                    height={70}
                    layout="fill"
                    src={asset.displayImageUrl ?? ""}
                    width={70}
                  />
                  {modalState.order.length > 7 && index === 6 && (
                    <Block
                      alignItems="center"
                      className="absolute left-0 top-0 w-full"
                      display="flex"
                      height="100%"
                      justifyContent="center"
                    >
                      <Block
                        className="w-full bg-black opacity-50"
                        height="100%"
                      />
                      <Block
                        className="absolute"
                        color="white"
                        fontSize="14px"
                        fontWeight="600"
                      >
                        +{modalState.order.length - index}{" "}
                        {t("featured.more", "more")}
                      </Block>
                    </Block>
                  )}
                </Block>
              ))}
            </Flex>
          </>
        )}
      </Modal.Body>
      {modalPage === "choose_items" && (
        <Modal.Footer>
          <Flex
            alignItems="center"
            className="w-full"
            justifyContent="space-between"
          >
            <Flex>
              {modalState.order.map((asset, idx) => (
                <StyledBlock
                  className="relative mr-3 rounded-lg"
                  height="48px"
                  key={asset.relayId}
                  overflow="hidden"
                  width="48px"
                >
                  <Thumbnail
                    alt={asset.name || ""}
                    height={48}
                    src={asset.displayImageUrl || ""}
                    width={48}
                  />
                  <DeleteButton
                    onClick={() =>
                      setModalState({
                        order: modalState.order.filter((_, i) => idx !== i),
                      })
                    }
                  >
                    <Icon value="cancel" />
                  </DeleteButton>
                </StyledBlock>
              ))}
            </Flex>
            <Modal.Footer.Button
              disabled={!modalState.order.length}
              onClick={() => {
                if (modalState.initialOrder.length > 0) {
                  trackFirstNextOnEditFeaturedShelf()
                } else {
                  trackFirstNextOnNewFeaturedShelf()
                }
                setModalPage("review_and_reorder")
              }}
            >
              {t("featured.nextCTA", "Next")}
            </Modal.Footer.Button>
          </Flex>
        </Modal.Footer>
      )}
      {modalPage === "review_and_reorder" && (
        <Modal.Footer>
          <Flex className="w-full" justifyContent="space-between">
            <Modal.Footer.Button
              variant="secondary"
              onClick={() => setModalPage("choose_items")}
            >
              {t("featured.backCTA", "Back")}
            </Modal.Footer.Button>
            <Modal.Footer.Button
              disabled={!modalState.order.length}
              onClick={() => {
                if (modalState.initialOrder.length > 0) {
                  trackSecondNextOnEditFeaturedShelf()
                } else {
                  trackSecondNextOnNewFeaturedShelf()
                }
                setModalPage("edit_details")
              }}
            >
              {t("featured.nextCTA", "Next")}
            </Modal.Footer.Button>
          </Flex>
        </Modal.Footer>
      )}
      {modalPage === "edit_details" && (
        <Modal.Footer>
          <Modal.Footer.Button
            variant="secondary"
            onClick={() => setModalPage("choose_items")}
          >
            {t("featured.backCTA", "Back")}
          </Modal.Footer.Button>
          <Modal.Footer.Button
            disabled={
              !modalState.title || !(modalState.title.trim().length > 0)
            }
            onClick={() => {
              if (modalState.initialOrder.length > 0) {
                trackDoneOnEditFeaturedShelf()
              } else {
                trackDoneOnNewFeaturedShelf()
              }
              onFinish(modalState)
            }}
          >
            {t("featured.doneCTA", "Done")}
          </Modal.Footer.Button>
        </Modal.Footer>
      )}
    </>
  )

  return (
    <>
      <Media lessThan="lg">
        <Modal
          {...modalProps}
          overrides={{
            Dialog: {
              props: { height: "100%", minHeight: "560px" },
            },
          }}
          size="xlarge"
        >
          {modalContent}
        </Modal>
      </Media>
      <Media greaterThanOrEqual="lg">
        <Modal
          {...modalProps}
          overrides={{
            Dialog: {
              props: {
                height: modalPage !== "edit_details" ? "88%" : undefined,
                minHeight: "560px",
              },
            },
          }}
          size={modalPage === "edit_details" ? "medium" : "xlarge"}
        >
          {modalContent}
        </Modal>
      </Media>
    </>
  )
}

const StyledBlock = styled(Block)`
  &:hover {
    cursor: pointer;
    user-select: none;
  }
`

const Thumbnail = styled(Image)`
  ${StyledBlock}:hover & {
    cursor: pointer;
    user-select: none;
    opacity: 0.2;
  }
`

const DeleteButton = styled(UnstyledButton)`
  ${StyledBlock}:hover & {
    cursor: pointer;
    user-select: none;
    align-items: center;
    color: ${props => props.theme.colors.error};
    display: flex;
    font-size: 20px;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

const StyledAssetSearchDropdown = styled(AssetSearchSortDropdown)`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  padding-right: 20px;
  padding-left: 24px;

  i {
    color: ${props => props.theme.colors.text.primary};
  }

  &:hover {
    i[aria-label="Show more"] {
      color: ${props => props.theme.colors.text.primary};
    }
  }
`

const Container = styled(Block)<{ transform?: string; transition?: string }>`
  transform: ${props => props.transform};
  transition: ${props => props.transition};
  &:hover {
    z-index: 1;
  }
`
