import React, { Suspense, useCallback, useEffect, useState } from "react"
import { graphql, useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { useActiveIdentity } from "@/containers/WalletProvider/WalletProvider.react"
import {
  FeaturedAddEditModal,
  FeaturedAddEditModalState,
  FEATURED_ITEM_LIMIT,
  Page,
} from "@/features/featured-shelf/components/FeaturedAddEditModal.react"
import {
  FeaturedAddEditModalLoadingState,
  FeaturedAddEditModalSearchResults,
} from "@/features/featured-shelf/components/FeaturedAddEditModalComponents.react"
import {
  FeaturedAddEditModalController_data$data,
  FeaturedAddEditModalController_data$key,
} from "@/lib/graphql/__generated__/FeaturedAddEditModalController_data.graphql"
import { FeaturedAddEditModalControllerAssetsQuery } from "@/lib/graphql/__generated__/FeaturedAddEditModalControllerAssetsQuery.graphql"
import { FeaturedAddEditModalControllerQuery } from "@/lib/graphql/__generated__/FeaturedAddEditModalControllerQuery.graphql"

export type QueriedFeaturedAsset = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        FeaturedAddEditModalController_data$data["search"]
      >["edges"][number]
    >["node"]
  >["asset"]
>

const FEATURED_ADD_EDIT_MODAL_EMPTY_STATE: FeaturedAddEditModalState = {
  id: null,
  tabs: ["collected", "created"],
  selectedTab: "collected",
  shelfDisplayOrder: 0,
  order: [],
  initialOrder: [],
  sort: {
    sortAscending: false,
    sortBy: "LAST_TRANSFER_DATE",
  },
  title: "",
  description: "",
  search: "",
  debouncedSearch: "",
  hydrated: false,
}

function getEmptyState() {
  return {
    ...FEATURED_ADD_EDIT_MODAL_EMPTY_STATE,
    tabs: [...FEATURED_ADD_EDIT_MODAL_EMPTY_STATE.tabs],
    order: [...FEATURED_ADD_EDIT_MODAL_EMPTY_STATE.order],
    sort: {
      ...FEATURED_ADD_EDIT_MODAL_EMPTY_STATE.sort,
    },
  }
}

function FeaturedAddEditModalQueryContainer({
  modalState,
  setModalState,
}: {
  modalState: FeaturedAddEditModalState
  setModalState: (state: Partial<FeaturedAddEditModalState>) => void
}) {
  const identity = useActiveIdentity()

  // Fetch data for any assets already on the shelf
  const shelfData = useLazyLoadQuery<FeaturedAddEditModalControllerAssetsQuery>(
    /* eslint-disable relay/unused-fields, relay/must-colocate-fragment-spreads */
    graphql`
      query FeaturedAddEditModalControllerAssetsQuery(
        $assets: [AssetRelayID!]!
      ) {
        assets(assets: $assets) {
          name
          collection {
            name
            displayData {
              cardDisplayStyle
            }
          }
          creator {
            address
          }
          imageUrl
          displayImageUrl
          assetEventData {
            lastSale {
              totalPriceQuantity {
                quantity
                asset {
                  usdSpotPrice
                }
              }
            }
          }
          relayId
          tokenId
          ...asset_url
        }
      }
    `,
    {
      assets: modalState.initialOrder,
    },
  )

  // Fetch first page of assets available to feature
  const initialAssets = useLazyLoadQuery<FeaturedAddEditModalControllerQuery>(
    graphql`
      query FeaturedAddEditModalControllerQuery(
        $count: Int
        $creator: IdentityInputType
        $cursor: String
        $identity: IdentityInputType
        $query: String
        $sortAscending: Boolean
        $sortBy: SearchSortBy
        $stringTraits: [TraitInputType!]
      ) {
        ...FeaturedAddEditModalController_data
          @arguments(
            count: $count
            creator: $creator
            cursor: $cursor
            identity: $identity
            query: $query
            sortAscending: $sortAscending
            sortBy: $sortBy
            stringTraits: $stringTraits
          )
      }
    `,
    {
      count: 20,
      creator: modalState.selectedTab === "created" ? identity : undefined,
      identity: modalState.selectedTab === "collected" ? identity : undefined,
      sortAscending: modalState.sort.sortAscending,
      sortBy: modalState.sort.sortBy,
      query: modalState.debouncedSearch,
    },
    { fetchPolicy: "network-only" },
  )

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    FeaturedAddEditModalControllerQuery,
    FeaturedAddEditModalController_data$key
  >(
    graphql`
      fragment FeaturedAddEditModalController_data on Query
      @argumentDefinitions(
        count: { type: "Int" }
        creator: { type: "IdentityInputType" }
        cursor: { type: "String" }
        identity: { type: "IdentityInputType" }
        query: { type: "String" }
        sortAscending: { type: "Boolean" }
        sortBy: { type: "SearchSortBy" }
        stringTraits: { type: "[TraitInputType!]" }
      )
      @refetchable(queryName: "FeaturedAddEditModalControllerPaginationQuery") {
        search(
          after: $cursor
          first: $count
          creator: $creator
          identity: $identity
          querystring: $query
          sortAscending: $sortAscending
          sortBy: $sortBy
          stringTraits: $stringTraits
        ) @connection(key: "FeaturedAddEditModalController_search") {
          edges {
            node {
              asset {
                name
                collection {
                  name
                  displayData {
                    cardDisplayStyle
                  }
                  isVerified
                }
                creator {
                  address
                }
                imageUrl
                displayImageUrl
                assetEventData {
                  lastSale {
                    totalPriceQuantity {
                      quantity
                      asset {
                        usdSpotPrice
                      }
                    }
                  }
                }
                relayId
                tokenId
                ...asset_url
              }
            }
          }
          totalCount
        }
      }
    `,
    initialAssets,
  )

  const maybeAssets = data.search.edges.map(edge => edge?.node?.asset)
  const assets = maybeAssets.filter(
    asset => !!asset,
  ) as unknown as QueriedFeaturedAsset[]

  const onSelectItem = useCallback(
    (item: QueriedFeaturedAsset) => {
      const index = modalState.order.findIndex(x => x.relayId === item.relayId)
      if (modalState.order.length < FEATURED_ITEM_LIMIT || index >= 0) {
        if (index >= 0) {
          setModalState({
            order: [
              ...modalState.order.slice(0, index),
              ...modalState.order.slice(index + 1, modalState.order.length),
            ],
          })
        } else {
          setModalState({
            order: [...modalState.order, item],
          })
        }
      }
    },
    [modalState, setModalState],
  )

  // This latches the "initial order" prop, so it only
  // hydrates the assets once on edit
  useEffect(() => {
    if (
      !modalState.hydrated &&
      modalState.initialOrder.length &&
      shelfData.assets.length
    ) {
      setModalState({
        order: modalState.initialOrder
          .map(item => shelfData.assets.find(x => x.relayId === item))
          .filter((x): x is QueriedFeaturedAsset => !!x),
        hydrated: true,
      })
    }
  }, [shelfData.assets, modalState, setModalState])

  if (!identity) {
    return null
  }

  return (
    <FeaturedAddEditModalSearchResults
      assets={assets}
      hasNext={hasNext}
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
      modalState={modalState}
      onSelectItem={onSelectItem}
    />
  )
}

export function FeaturedAddEditModalController({
  page,
  initialState,
  onChangePage,
  onCancel,
  onFinish,
}: {
  page: Page
  initialState: Partial<FeaturedAddEditModalState>
  onChangePage: (page: Page) => void
  onCancel: () => void
  onFinish: (state: FeaturedAddEditModalState) => void
}) {
  // "Modal page" state lives in the containing settings page
  // Because this modal has two entrypoints and varies in size
  const [state, setState] = useState<FeaturedAddEditModalState>({
    ...getEmptyState(),
    ...initialState,
  })

  // Callback to merge state updates. Violates open-closed
  // principle to be setting state properties directly, but it's just
  // within this component, and I'm not ready for a reducer here.
  const setModalState = useCallback(
    (merge: Partial<FeaturedAddEditModalState>) =>
      setState({ ...state, ...merge }),
    [state, setState],
  )

  return (
    <FeaturedAddEditModal
      modalPage={page}
      modalState={state}
      setModalPage={onChangePage}
      setModalState={setModalState}
      onCancel={onCancel}
      onFinish={onFinish}
    >
      <Suspense fallback={<FeaturedAddEditModalLoadingState />}>
        <FeaturedAddEditModalQueryContainer
          modalState={state}
          setModalState={setModalState}
        />
      </Suspense>
    </FeaturedAddEditModal>
  )
}
