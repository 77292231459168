/**
 * @generated SignedSource<<395ea01c62d67fcd076bb85589c5acae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CardDisplayStyle = "CONTAIN" | "COVER" | "PADDED" | "%future added value";
export type FeaturedAddEditModalControllerAssetsQuery$variables = {
  assets: ReadonlyArray<string>;
};
export type FeaturedAddEditModalControllerAssetsQuery$data = {
  readonly assets: ReadonlyArray<{
    readonly assetEventData: {
      readonly lastSale: {
        readonly totalPriceQuantity: {
          readonly asset: {
            readonly usdSpotPrice: number | null;
          };
          readonly quantity: string;
        } | null;
      } | null;
    };
    readonly collection: {
      readonly displayData: {
        readonly cardDisplayStyle: CardDisplayStyle | null;
      };
      readonly name: string;
    };
    readonly creator: {
      readonly address: string;
    } | null;
    readonly displayImageUrl: string | null;
    readonly imageUrl: string | null;
    readonly name: string | null;
    readonly relayId: string;
    readonly tokenId: string;
    readonly " $fragmentSpreads": FragmentRefs<"asset_url">;
  }>;
};
export type FeaturedAddEditModalControllerAssetsQuery = {
  response: FeaturedAddEditModalControllerAssetsQuery$data;
  variables: FeaturedAddEditModalControllerAssetsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assets"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "assets",
    "variableName": "assets"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "DisplayDataType",
  "kind": "LinkedField",
  "name": "displayData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cardDisplayStyle",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayImageUrl",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usdSpotPrice",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tokenId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = [
  (v4/*: any*/),
  (v13/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeaturedAddEditModalControllerAssetsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssetType",
        "kind": "LinkedField",
        "name": "assets",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountType",
            "kind": "LinkedField",
            "name": "creator",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ESAssetEventDataType",
            "kind": "LinkedField",
            "name": "assetEventData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ESAssetEventType",
                "kind": "LinkedField",
                "name": "lastSale",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssetQuantityType",
                    "kind": "LinkedField",
                    "name": "totalPriceQuantity",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssetType",
                        "kind": "LinkedField",
                        "name": "asset",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "kind": "InlineDataFragmentSpread",
            "name": "asset_url",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetContractType",
                "kind": "LinkedField",
                "name": "assetContract",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeaturedAddEditModalControllerAssetsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssetType",
        "kind": "LinkedField",
        "name": "assets",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountType",
            "kind": "LinkedField",
            "name": "creator",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ESAssetEventDataType",
            "kind": "LinkedField",
            "name": "assetEventData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ESAssetEventType",
                "kind": "LinkedField",
                "name": "lastSale",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssetQuantityType",
                    "kind": "LinkedField",
                    "name": "totalPriceQuantity",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssetType",
                        "kind": "LinkedField",
                        "name": "asset",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetContractType",
            "kind": "LinkedField",
            "name": "assetContract",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          (v12/*: any*/),
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "41d93405ab5da988c4bf7ad1610c7375",
    "id": null,
    "metadata": {},
    "name": "FeaturedAddEditModalControllerAssetsQuery",
    "operationKind": "query",
    "text": "query FeaturedAddEditModalControllerAssetsQuery(\n  $assets: [AssetRelayID!]!\n) {\n  assets(assets: $assets) {\n    name\n    collection {\n      name\n      displayData {\n        cardDisplayStyle\n      }\n      id\n    }\n    creator {\n      address\n      id\n    }\n    imageUrl\n    displayImageUrl\n    assetEventData {\n      lastSale {\n        totalPriceQuantity {\n          quantity\n          asset {\n            usdSpotPrice\n            id\n          }\n          id\n        }\n      }\n    }\n    relayId\n    tokenId\n    ...asset_url\n    id\n  }\n}\n\nfragment asset_url on AssetType {\n  assetContract {\n    address\n    id\n  }\n  tokenId\n  chain {\n    identifier\n  }\n}\n"
  }
};
})();

(node as any).hash = "f6e721a85b1d9006189be84fcda1648d";

export default node;
