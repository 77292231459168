import React, { useEffect, useMemo, useState } from "react"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import {
  FlexColumn,
  Icon,
  Media,
  SpaceBetween,
  Text,
  TextBodyProps,
  classNames,
  useIsLessThanXXl,
} from "@opensea/ui-kit"
import { rgba } from "polished"
import { graphql, useFragment } from "react-relay"
import { useUpdateEffect } from "react-use"
import styled, { useTheme } from "styled-components"
import { LegacyAssetCard } from "@/components/assets/LegacyAssetCard"
import { Link } from "@/components/common/Link"
import { useTrackClickLink } from "@/components/common/Link/analytics"
import { OverflowContainer } from "@/components/common/Overflow"
import {
  getCardVariantMinWidth,
  AssetCardVariantToggle,
} from "@/components/layout/AccountOrCollectionPage/components/AssetCardVariantToggle"
import {
  AssetCardVariant,
  updateCardVariantCookie,
} from "@/components/search/assets/AssetSearchView"
import { IS_SERVER } from "@/constants/environment"
import { Block } from "@/design-system/Block"
import { Dropdown } from "@/design-system/Dropdown"
import { Flex } from "@/design-system/Flex"
import { Gallery, GalleryProps } from "@/design-system/Gallery"
import { IconButton } from "@/design-system/IconButton"
import { Image } from "@/design-system/Image"
import { deleteShelfFromStore } from "@/features/featured-shelf/utils/FeaturedShelfHelpers"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useRouter } from "@/hooks/useRouter"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import {
  trackDeleteFeaturedShelf,
  trackOpenFeaturedShelf,
} from "@/lib/analytics/events/featuredShelfEvents"
import { FeaturedItemsQuery } from "@/lib/graphql/__generated__/FeaturedItemsQuery.graphql"
import {
  FeaturedShelfAssets_data$data,
  FeaturedShelfAssets_data$key,
} from "@/lib/graphql/__generated__/FeaturedShelfAssets_data.graphql"
import { FeaturedShelfAssetsCount_shelf$data } from "@/lib/graphql/__generated__/FeaturedShelfAssetsCount_shelf.graphql"
import { FeaturedShelfDeleteShelfMutation } from "@/lib/graphql/__generated__/FeaturedShelfDeleteShelfMutation.graphql"
import { FeaturedShelfUpdateMutation } from "@/lib/graphql/__generated__/FeaturedShelfUpdateMutation.graphql"
import { profilePageQueries_featured$data } from "@/lib/graphql/__generated__/profilePageQueries_featured.graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { getAssetUrl } from "@/lib/helpers/asset"
import { themeVariant } from "@/styles/styleUtils"
import { FeaturedAddEditModalState, Page } from "./FeaturedAddEditModal.react"
import { FeaturedAddEditModalController } from "./FeaturedAddEditModalController.react"
import { FeaturedShelfDeleteModal } from "./FeaturedShelfDeleteModal.react"

type FeaturedShelfQueried = Exclude<
  profilePageQueries_featured$data["shelves"],
  null
>[number]

type FeaturedShelfListProps = {
  accountId: string
  cardVariant?: AssetCardVariant
  setCardVariant: React.Dispatch<
    React.SetStateAction<AssetCardVariant | undefined>
  >
  shelves?: readonly (FeaturedShelfQueried | null)[] // A shelf can be null after deleting it
  showExtraMenu?: boolean
  isProfilePage?: boolean
  refetch?: () => void
}

export const shelfSort = (a: ShelfData | null, b: ShelfData | null) =>
  (a?.displayOrder || 0) - (b?.displayOrder || 0)

export const FeaturedShelfList = ({
  accountId,
  cardVariant,
  setCardVariant,
  shelves,
  showExtraMenu,
  refetch,
  isProfilePage,
}: FeaturedShelfListProps) => {
  useEffect(() => {
    // List view not supported for featured shelves.
    if (cardVariant === "list-view") {
      setCardVariant("cozy")
    }
  }, [cardVariant, setCardVariant])

  useUpdateEffect(
    () => updateCardVariantCookie("account", cardVariant),
    [cardVariant],
  )

  if (!shelves?.length) {
    return null
  }

  // TODO(dragonbear): This is a problem because it is conditionally calling hooks, needs to be
  // cleaned up.
  const filteredShelves = shelves
    .slice()
    .filter((shelf: FeaturedShelfQueried | null | undefined) => !!shelf)
    .sort(shelfSort)
    .filter(shelf => shelf && readShelfAssetsCount(shelf) > 0)

  return (
    // NOTE(@laurafiuza) top padding of asset media is 8px so we subtract 8px to account for that
    <FlexColumn className="mx-0 mb-10 mt-5 gap-8 sm:mx-0 sm:mb-20 sm:gap-11">
      {filteredShelves.map(
        (shelf, idx) =>
          shelf && (
            <FeaturedShelf
              accountId={accountId}
              assets={shelf}
              cardVariant={cardVariant}
              description={shelf.description}
              displayAssetCardToggle={idx === 0}
              displayOrder={shelf.displayOrder}
              isProfilePage={isProfilePage}
              key={shelf.id}
              name={shelf.name}
              refetch={refetch}
              setCardVariant={setCardVariant}
              shelfId={shelf.id}
              showExtraMenu={showExtraMenu ?? false}
            />
          ),
      )}
    </FlexColumn>
  )
}

type FeaturedShelfProps = {
  accountId: string
  assets: FeaturedShelfAssets_data$key
  cardVariant?: AssetCardVariant
  setCardVariant: React.Dispatch<
    React.SetStateAction<AssetCardVariant | undefined>
  >
  description: string | null
  name: string
  shelfId: string
  displayOrder: number
  showExtraMenu: boolean
  refetch?: () => void
  isProfilePage?: boolean
  displayAssetCardToggle: boolean
}

const readShelfAssetsCount = inlineFragmentize<
  FeaturedShelfAssetsCount_shelf$data,
  number
>(
  graphql`
    fragment FeaturedShelfAssetsCount_shelf on AccountShelfType @inline {
      shelfAssets {
        __typename
      }
    }
  `,
  shelf => shelf.shelfAssets?.length ?? 0,
)

const FeaturedShelfAssetsFragment = graphql`
  fragment FeaturedShelfAssets_data on AccountShelfType
  @argumentDefinitions(
    showContextMenu: { type: "Boolean", defaultValue: false }
  ) {
    shelfAssets {
      id
      displayOrder
      asset {
        id
        name
        displayImageUrl
        ...asset_url
      }
      ...LegacyAssetCard_data @arguments(showContextMenu: $showContextMenu)
    }
  }
`

const useCommit = (
  shelfId: string,
  isProfilePage: boolean,
  refetch?: () => void,
) => {
  const t = useTranslate("phoenix")
  const { mutate } = useGraphQL()
  const { attempt, showSuccessMessage } = useToasts()
  return async (state: FeaturedAddEditModalState) =>
    await attempt(
      async () => {
        await mutate<FeaturedShelfUpdateMutation>(
          graphql`
            mutation FeaturedShelfUpdateMutation(
              $shelfId: AccountShelfScalar!
              $assets: [AssetRelayID!]!
              $name: String!
              $description: String!
              $displayOrder: Int!
            ) {
              accounts {
                updateAccountShelf(
                  accountShelf: $shelfId
                  assets: $assets
                  shelfData: {
                    name: $name
                    description: $description
                    displayOrder: $displayOrder
                  }
                ) {
                  id
                  name
                  description
                  displayOrder
                  shelfAssets {
                    id
                    displayOrder
                    asset {
                      name
                      description
                      imageUrl
                    }
                  }
                }
              }
            }
          `,
          {
            shelfId,
            assets: state.order.map(asset =>
              typeof asset === "string" ? asset : asset.relayId,
            ),
            name: state.title,
            description: state.description || "",
            displayOrder: state.shelfDisplayOrder || 0,
          },
          {
            shouldAuthenticate: true,
          },
        )
        refetch && setTimeout(refetch, 1000) // Give the read replica 1s to update
        showSuccessMessage(
          <FeaturedSuccessMessage
            isProfilePage={isProfilePage}
            message={t(
              "featured.updateSuccessMessage",
              "Featured section has been updated.",
            )}
          />,
        )
      },
      { onError: () => window.location.reload() },
    )
}

export const FeaturedSuccessMessage = ({
  message,
  isProfilePage,
}: {
  message: string
  isProfilePage: boolean
}) => {
  const t = useTranslate("collections")
  return (
    <Block>
      {message}
      {!isProfilePage && (
        <Link className="ml-2">{t("featured.viewCTA", "View")}</Link>
      )}
    </Block>
  )
}

export const FeaturedShelf = ({
  accountId,
  assets: assetsDataKey,
  cardVariant,
  description,
  name,
  shelfId,
  displayOrder,
  setCardVariant,
  showExtraMenu,
  refetch,
  isProfilePage,
  displayAssetCardToggle,
}: FeaturedShelfProps) => {
  const router = useRouter()
  const trackClickLink = useTrackClickLink()

  const shelf = useFragment(FeaturedShelfAssetsFragment, assetsDataKey)

  const assets = useMemo(() => {
    return (shelf.shelfAssets || [])
      .slice()
      .sort((a, b) => a.displayOrder - b.displayOrder)
  }, [shelf.shelfAssets])

  if (!assets.length) {
    return null
  }

  type ShelfAssetsType = (typeof assets)[number]

  const SIDE_PADDING = 16

  const galleryProps: Omit<GalleryProps<ShelfAssetsType>, "itemMinWidth"> = {
    getKey: asset => asset.id || "",
    gridGap: 16,
    items: assets as ShelfAssetsType[],
    renderItem: item => (
      <LegacyAssetCard
        data={item.data}
        key={item.data.id}
        variant={cardVariant}
        width={item.width}
        onClick={() => {
          const url = item.data.asset && getAssetUrl(item.data.asset)
          if (url) {
            trackClickLink({
              url,
              currentUrl: window.location.href,
              source: "featured shelf",
              type: "internal",
            })
            router.push(url)
          }
        }}
      />
    ),
    sidePadding: SIDE_PADDING,
    variant: cardVariant === "natural" ? "grid" : "flex-wrap",
  }

  return (
    <Block>
      <TitleBar
        accountId={accountId}
        assets={assets}
        cardVariant={cardVariant}
        description={description}
        displayAssetCardToggle={displayAssetCardToggle}
        displayOrder={displayOrder}
        isProfilePage={isProfilePage}
        name={name}
        refetch={refetch}
        setCardVariant={setCardVariant}
        shelfId={shelfId}
        showExtraMenu={showExtraMenu}
      />
      <Media lessThan="sm">
        <Block marginX="-8px">
          <Gallery
            key={cardVariant}
            {...galleryProps}
            itemMinWidth={
              /* NOTE(@laurafiuza): Have to subtract SIDE_PADDING from the itemMinWidth because of
              how we compute card widths to get the edge to edge justified look on featured shelves
              to match what the masonic grid. */
              getCardVariantMinWidth("mobile", cardVariant ?? "compact") -
              SIDE_PADDING
            }
          />
        </Block>
      </Media>
      <Media greaterThanOrEqual="sm">
        <Block marginX="-8px">
          <Gallery
            key={cardVariant}
            {...galleryProps}
            itemMinWidth={getCardVariantMinWidth(
              "desktop",
              cardVariant ?? "cozy",
            )}
          />
        </Block>
      </Media>
    </Block>
  )
}

type TitleBarContentProps = {
  name: string
  overrides?: {
    Container?: {
      className: string
    }
    Name?: Omit<TextBodyProps, "asChild"> & {
      as?: "h4" | "h6" | "p"
    }
    Description?: TextBodyProps
  }
  description: string | null
}

const TitleBarContent = ({
  name,
  description,
  overrides,
}: TitleBarContentProps) => {
  const isXXL = !useIsLessThanXXl()
  const NameElement = overrides?.Name?.as || "p"

  return (
    <FlexColumn
      className={classNames(
        "max-w-[600px] xxl:max-w-[900px]",
        overrides?.Container?.className,
      )}
    >
      <Text {...overrides?.Name} asChild>
        <NameElement>
          <OverflowContainer $lines={1} data-testid="featured-section-title">
            {name}
          </OverflowContainer>
        </NameElement>
      </Text>
      {description && (
        <Text
          {...overrides?.Description}
          className={classNames(overrides?.Description?.className)}
          color={overrides?.Description?.color || "secondary"}
          data-testid="featured-section-description"
        >
          {isXXL ? (
            description
          ) : (
            <OverflowContainer $lines={1}>{description}</OverflowContainer>
          )}
        </Text>
      )}
    </FlexColumn>
  )
}

type TitleBarProps = {
  accountId: string
  name: string
  description: string | null
  shelfId: string
  displayOrder: number
  showExtraMenu: boolean
  assets: FeaturedShelfAssets_data$data["shelfAssets"]
  refetch?: () => void
  isProfilePage?: boolean
  setCardVariant: React.Dispatch<
    React.SetStateAction<AssetCardVariant | undefined>
  >
  cardVariant?: AssetCardVariant
  displayAssetCardToggle: boolean
}

const TitleBar = ({
  accountId,
  name,
  description,
  shelfId,
  displayOrder,
  assets,
  showExtraMenu,
  refetch,
  isProfilePage,
  setCardVariant,
  cardVariant,
  displayAssetCardToggle,
}: TitleBarProps) => {
  const t = useTranslate("phoenix")
  const commit = useCommit(shelfId, !!isProfilePage, refetch)
  const {
    isOpen: isEditModalOpen,
    open: openEditModal,
    close: closeEditModal,
  } = useIsOpen()
  const {
    isOpen: deleteShelfIsOpen,
    open: openDeleteShelf,
    close: closeDeleteShelf,
  } = useIsOpen()

  const { mutate } = useGraphQL()
  const { attempt, showSuccessMessage } = useToasts()
  const onDelete = async (shelfId: string) => {
    await attempt(async () => {
      await mutate<FeaturedShelfDeleteShelfMutation>(
        graphql`
          mutation FeaturedShelfDeleteShelfMutation(
            $shelfId: AccountShelfScalar!
          ) {
            accounts {
              deleteAccountShelf(accountShelf: $shelfId)
            }
          }
        `,
        {
          shelfId,
        },
        {
          shouldAuthenticate: true,
          updater: store => deleteShelfFromStore({ store, accountId, shelfId }),
        },
      )
      showSuccessMessage(
        t("featured.deleteSuccessMessage", "Featured section has been deleted"),
      )
      trackDeleteFeaturedShelf()
    })
  }

  const assetIds = (assets || []).map(x => x.asset?.id || "").filter(x => x)

  const [addEditModalPage, setAddEditModalPage] = useState<Page>("choose_items")

  const extraMenu = (
    <>
      {isEditModalOpen && (
        <FeaturedAddEditModalController
          initialState={{
            id: shelfId,
            title: name,
            description,
            initialOrder: assetIds,
            shelfDisplayOrder: displayOrder,
          }}
          page={addEditModalPage}
          onCancel={closeEditModal}
          onChangePage={setAddEditModalPage}
          onFinish={state => {
            closeEditModal()
            commit(state)
            refetch?.()
          }}
        />
      )}
      <FeaturedShelfExtraMenu
        isProfilePage={isProfilePage}
        onDelete={openDeleteShelf}
        onEdit={() => {
          setAddEditModalPage("choose_items")
          openEditModal()
        }}
      />
      <FeaturedShelfDeleteModal
        isOpen={deleteShelfIsOpen}
        onClose={closeDeleteShelf}
        onDelete={() => {
          closeDeleteShelf()
          onDelete(shelfId)
        }}
      />
    </>
  )

  return (
    <SpaceBetween>
      <Media greaterThanOrEqual="sm">
        <FlexColumn className="mt-5">
          <TitleBarContent
            description={description}
            name={name}
            overrides={{
              Container: {
                // NOTE(@laurafiuza): Should be mb-8 but the top padding of the asset media is 8px so we account for that
                className: "mb-6",
              },
              Description: {
                className: "mt-0.5",
                size: "medium",
              },
              Name: {
                as: "h4",
                className: "text-heading-sm",
                color: "primary",
                weight: "semibold",
              },
            }}
          />
        </FlexColumn>
      </Media>
      <Media lessThan="sm">
        <FlexColumn>
          <TitleBarContent
            description={description}
            name={name}
            overrides={{
              Container: {
                // NOTE(@laurafiuza): Should be 24px but the top padding of the asset media is 8px so we account for that
                className: "mb-4",
              },
              Description: {
                size: "small",
              },
              Name: {
                as: "h6",
                className: "leading-xs",
                color: "primary",
                size: "small",
                weight: "semibold",
              },
            }}
          />
        </FlexColumn>
      </Media>
      {(showExtraMenu || displayAssetCardToggle) && (
        <Media greaterThanOrEqual="lg">
          <Flex marginTop="8px">
            {/* Only show the asset card variant toggle on the first title bar */}
            {displayAssetCardToggle && (
              <Flex marginRight={showExtraMenu ? "12px" : undefined}>
                <AssetCardVariantToggle
                  currentCardVariant={cardVariant}
                  setCurrentCardVariant={setCardVariant}
                />
              </Flex>
            )}
            {showExtraMenu && extraMenu}
          </Flex>
        </Media>
      )}
    </SpaceBetween>
  )
}

type FeaturedShelfExtraMenuProps = {
  onDelete?: () => unknown
  onEdit?: () => unknown
  isProfilePage?: boolean
}

const FeaturedShelfExtraMenu = ({
  onDelete,
  onEdit,
  isProfilePage,
}: FeaturedShelfExtraMenuProps) => {
  const t = useTranslate("phoenix")
  return (
    <Dropdown
      appendTo={IS_SERVER ? undefined : document.body}
      content={({ close, List, Item }) => (
        <List>
          {onEdit ? (
            <Item
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                trackOpenFeaturedShelf({
                  openedFrom: isProfilePage
                    ? "edit on profile page"
                    : "edit on settings",
                })
                onEdit()
                close()
              }}
            >
              <Item.Avatar icon="edit" />
              <Item.Content>
                <Item.Title>{t("featured.editCTA", "Edit")}</Item.Title>
              </Item.Content>
            </Item>
          ) : null}
          {onDelete ? (
            <Item
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                onDelete()
                close()
              }}
            >
              <Item.Avatar className="text-red-2" icon="delete" />
              <Item.Content>
                <Item.Title color="error">
                  {t("featured.deleteCTA", "Delete")}
                </Item.Title>
              </Item.Content>
            </Item>
          ) : null}
        </List>
      )}
      placement="bottom-end"
    >
      <IconButton aria-label="More">
        <Icon value="more_horiz" weight={600} />
      </IconButton>
    </Dropdown>
  )
}

type ShelfData = NonNullable<
  FeaturedItemsQuery["response"]["getAccount"]["shelves"]
>[number]

export function SortableFeaturedShelf({
  shelf,
  onDelete,
  refetch,
}: {
  shelf: ShelfData
  onDelete: () => void
  refetch: () => void
}) {
  const t = useTranslate("phoenix")
  const theme = useTheme()
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useSortable({ id: shelf.id })

  const commit = useCommit(shelf.id, false, () => undefined)
  const {
    isOpen: isEditModalOpen,
    open: openEditModal,
    close: closeEditModal,
  } = useIsOpen()
  const {
    isOpen: deleteShelfIsOpen,
    open: openDeleteShelf,
    close: closeDeleteShelf,
  } = useIsOpen()

  const shelfData = useFragment<FeaturedShelfAssets_data$key>(
    FeaturedShelfAssetsFragment,
    shelf,
  )
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const assetIds = (shelfData?.shelfAssets || [])
    .map(x => x.asset?.id)
    .filter((x): x is string => !!x)

  const displayedAssetIds = assetIds.slice(0, 4)
  const additionalAssets = assetIds.length - displayedAssetIds.length

  const [addEditModalPage, setAddEditModalPage] = useState<Page>("choose_items")
  const [error, setError] = useState<boolean>(false)

  return (
    <SortableContainer
      boxShadow={isDragging ? theme.shadows.header : undefined}
      className={classNames(
        "rounded-lg bg-base-1",
        isDragging ? "cursor-pointer" : undefined,
      )}
      height={shelf.description ? "80px" : "48px"}
      margin="0 -8px"
      padding="0 8px"
      ref={setNodeRef}
      transform={CSS.Transform.toString({
        x: 0,
        y: transform?.y || 0,
        scaleX: 1,
        scaleY: 1,
      })}
      {...attributes}
    >
      <Flex
        justifyContent="space-between"
        margin={shelf.description ? "16px 0" : undefined}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          {...listeners}
          className="w-full cursor-grab"
          draggable
        >
          <Flex flexDirection="column">
            <OverflowContainer $lines={1}>
              <Text
                asChild
                className="leading-sm"
                color="primary"
                size="medium"
                weight="semibold"
              >
                <h5>{shelf.name}</h5>
              </Text>
            </OverflowContainer>
            {shelf.description && (
              <Block>
                <OverflowContainer $lines={1}>
                  <Text asChild color="secondary">
                    <div>{shelf.description}</div>
                  </Text>
                </OverflowContainer>
              </Block>
            )}
          </Flex>
          <Flex alignItems="center" marginLeft="52px">
            <Media greaterThanOrEqual="xs">
              <Flex alignItems="center">
                {displayedAssetIds.map((id, index) => {
                  const extra =
                    index === displayedAssetIds.length - 1
                      ? additionalAssets
                      : 0
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  const shelfAsset = (shelfData?.shelfAssets || []).find(
                    (
                      x,
                    ): x is NonNullable<
                      FeaturedShelfAssets_data$data["shelfAssets"]
                    >[number] => x.asset?.id === id,
                  )
                  return (
                    shelfAsset?.asset?.displayImageUrl && (
                      <Block
                        className="relative rounded-lg"
                        height="40px"
                        key={id}
                        marginRight="8px"
                        overflow="hidden"
                        width="40px"
                      >
                        {error ? (
                          <FallbackImage height="40px" width="40px" />
                        ) : (
                          <Image
                            alt={
                              shelfAsset.asset.name ||
                              t("featured.item", "Item")
                            }
                            height={40}
                            src={shelfAsset.asset.displayImageUrl}
                            unoptimized
                            width={40}
                            onError={() => setError(true)}
                          />
                        )}
                        {extra && (
                          <Flex
                            alignItems="center"
                            backgroundColor="rgba(0,0,0,.6)"
                            className="absolute left-0 top-0 rounded-lg"
                            height="40px"
                            justifyContent="center"
                            width="40px"
                          >
                            <Text color="white" size="small" weight="semibold">
                              +{extra + 1}
                            </Text>
                          </Flex>
                        )}
                      </Block>
                    )
                  )
                })}
              </Flex>
            </Media>
          </Flex>
        </Flex>{" "}
        <Flex alignItems="flex-start" marginLeft="8px">
          {isEditModalOpen && (
            <FeaturedAddEditModalController
              initialState={{
                id: shelf.id,
                title: shelf.name,
                description: shelf.description,
                initialOrder: assetIds,
                shelfDisplayOrder: shelf.displayOrder,
              }}
              page={addEditModalPage}
              onCancel={closeEditModal}
              onChangePage={setAddEditModalPage}
              onFinish={state => {
                closeEditModal()
                commit(state)
                refetch()
              }}
            />
          )}
          <FeaturedShelfDeleteModal
            isOpen={deleteShelfIsOpen}
            onClose={closeDeleteShelf}
            onDelete={() => {
              closeDeleteShelf()
              onDelete()
            }}
          />
        </Flex>
        <FeaturedShelfExtraMenu
          onDelete={openDeleteShelf}
          onEdit={() => {
            setAddEditModalPage("choose_items")
            openEditModal()
          }}
        />
      </Flex>
    </SortableContainer>
  )
}

const SortableContainer = styled(Block)<{
  transform?: string
  transition?: string
}>`
  transform: ${props => props.transform};
  transition: ${props => props.transition};
  &:hover {
    z-index: 1;
  }
`

const FallbackImage = styled(Block)`
  ${props =>
    themeVariant({
      variants: {
        light: {
          backgroundColor: rgba(props.theme.colors.fog, 0.5),
        },
        dark: {
          backgroundColor: props.theme.colors.oil,
        },
      },
    })}
`
