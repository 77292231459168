import {
  RecordProxy,
  RecordSourceSelectorProxy,
  MutationParameters,
} from "relay-runtime"

type DeleteShelfFromStoreArgs<TOperation extends MutationParameters> = {
  store: RecordSourceSelectorProxy<TOperation["response"]>
  accountId: string | undefined
  shelfId: string
}

type ReorderShelvesArgs<TOperation extends MutationParameters> = {
  store: RecordSourceSelectorProxy<TOperation["response"]>
  accountId: string | undefined
  shelfIds: string[]
}

const shelvesField = "shelves"
const displayOrderField = "displayOrder"

export const deleteShelfFromStore = <TOperation extends MutationParameters>({
  store,
  accountId,
  shelfId,
}: DeleteShelfFromStoreArgs<TOperation>) => {
  if (!accountId) {
    return
  }

  const account = store.get(accountId)
  if (!account) {
    // this should not happen
    store.delete(shelfId)
    return
  }
  const shelves = account.getLinkedRecords(shelvesField)
  if (!shelves) {
    // this should also not happen
    store.delete(shelfId)
    return
  }
  const idxToDelete = shelves.findIndex(proxy => proxy.getDataID() === shelfId)
  if (idxToDelete > -1) {
    const newShelves = shelves.slice()
    newShelves.splice(idxToDelete, 1)
    account.setLinkedRecords(newShelves, shelvesField)
  }
  store.delete(shelfId)
}

export const reorderShelves = <TOperation extends MutationParameters>({
  store,
  accountId,
  shelfIds,
}: ReorderShelvesArgs<TOperation>) => {
  if (!accountId) {
    return
  }

  const account = store.get(accountId)
  if (!account) {
    // this should not happen
    return
  }
  const shelves = account.getLinkedRecords(shelvesField)
  if (!shelves) {
    // this should also not happen
    return
  }
  const shelfMap: { [shelfId: string]: RecordProxy } = {}
  for (const shelf of shelves) {
    shelfMap[shelf.getDataID()] = shelf
  }
  const newShelves: RecordProxy[] = []
  let displayOrder = 0
  for (const shelfId of shelfIds) {
    const proxy = shelfMap[shelfId]
    proxy.setValue(displayOrder++, displayOrderField)
    newShelves.push(proxy)
  }
  account.setLinkedRecords(newShelves, shelvesField)
}
