import { Sort } from "@/components/search/assets/AssetSearchSortDropdown.react"

export const FEATURED_SHELF_SORTS: Sort[] = [
  { sortAscending: false, sortBy: "LAST_TRANSFER_DATE" },
  { sortAscending: true, sortBy: "UNIT_PRICE" },
  { sortAscending: false, sortBy: "UNIT_PRICE" },
  { sortAscending: false, sortBy: "LAST_SALE_PRICE" },
  { sortAscending: false, sortBy: "VIEWER_COUNT" },
  { sortAscending: false, sortBy: "FAVORITE_COUNT" },
]
