import React from "react"
import { Text } from "@opensea/ui-kit"
import { Modal } from "@/design-system/Modal"
import { useTranslate } from "@/hooks/useTranslate"

type Props = {
  isOpen: boolean
  onClose: () => unknown
  onDelete: () => unknown
}

export const FeaturedShelfDeleteModal = ({
  isOpen,
  onClose,
  onDelete,
}: Props) => {
  const t = useTranslate("phoenix")
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header />
      <Modal.Body centerAligned>
        <Modal.Body.Title>
          {t("featured.deleteModal.title", "Delete section")}
        </Modal.Body.Title>
        <Text.Body
          asChild
          className="my-6 text-center"
          size="medium"
          weight="semibold"
        >
          <p>
            {t(
              "featured.deleteModal.prompt",
              "Are you sure you want to delete this section?",
            )}
          </p>
        </Text.Body>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button variant="secondary" onClick={onClose}>
          {t("featured.deleteModal.cancelCTA", "Cancel")}
        </Modal.Footer.Button>
        <Modal.Footer.Button variant="destructive" onClick={onDelete}>
          {t("featured.deleteModal.confirmCTA", "Confirm")}
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  )
}
