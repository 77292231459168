import { getTrackingFn } from "../utils"

type FeaturedShelfParams = {
  openedFrom: string
}

export const trackOpenFeaturedShelf = getTrackingFn<FeaturedShelfParams>(
  "open featured shelf modal",
)

export const trackFirstNextOnEditFeaturedShelf = getTrackingFn(
  "click first next button on edit featured shelf modal",
)

export const trackSecondNextOnEditFeaturedShelf = getTrackingFn(
  "click second next button on edit featured shelf modal",
)

export const trackDoneOnEditFeaturedShelf = getTrackingFn(
  "click done button on edit featured shelf modal",
)

export const trackFirstNextOnNewFeaturedShelf = getTrackingFn(
  "click first next button on new featured shelf modal",
)

export const trackSecondNextOnNewFeaturedShelf = getTrackingFn(
  "click second next button on new featured shelf modal",
)

export const trackDoneOnNewFeaturedShelf = getTrackingFn(
  "click done button on new featured shelf modal",
)

export const trackCloseNewFeaturedShelf = getTrackingFn(
  "close new featured shelf modal",
)

export const trackDeleteFeaturedShelf = getTrackingFn(
  "confirm delete featured section",
)
