import React from "react"
import { useFragment } from "react-relay"
import { ItemCard, ItemCardProps } from "@/components/assets/ItemCard.react"
import {
  LegacyAssetCard_data$data,
  LegacyAssetCard_data$key,
} from "@/lib/graphql/__generated__/LegacyAssetCard_data.graphql"
import { graphql } from "@/lib/graphql/graphql"

const LegacyAssetCardDataFragment = graphql`
  fragment LegacyAssetCard_data on AccountShelfAssetType
  @argumentDefinitions(
    showContextMenu: { type: "Boolean", defaultValue: false }
  ) {
    asset {
      ...ItemCard_data @arguments(showContextMenu: $showContextMenu)
    }
  }
`

type Props = Pick<ItemCardProps, "variant" | "fillContainerWidth"> & {
  className?: string
  data: LegacyAssetCard_data$key
  isDisabled?: boolean
  showContextMenu?: boolean
  width: number
  containerWidth?: number
  onClick?: (assetDataKey: LegacyAssetCard_data$data) => void
  currentAddress?: string
}

/**
 * @deprecated Use ItemCard directly.
 */
export const LegacyAssetCard = ({
  data: dataKey,
  width,
  showContextMenu,
  className,
  isDisabled,
  containerWidth,
  onClick,
  currentAddress,
  ...rest
}: Props) => {
  const data = useFragment(LegacyAssetCardDataFragment, dataKey)
  return (
    <ItemCard
      accountAddress={currentAddress}
      className={className}
      containerWidth={containerWidth}
      data={data.asset}
      isDisabled={isDisabled}
      showContextMenu={showContextMenu}
      width={width}
      onClick={assetData =>
        assetData &&
        onClick?.(assetData as unknown as LegacyAssetCard_data$data)
      }
      {...rest}
    />
  )
}
