import React from "react"
import {
  Icon,
  Text,
  Spinner,
  VerticalAligned,
  classNames,
} from "@opensea/ui-kit"
import { LoadMoreFn } from "react-relay"
import styled, { useTheme } from "styled-components"
import { VerificationIcon } from "@/components/collections/VerificationIcon.react"
import { OverflowContainer } from "@/components/common/Overflow"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { Gallery } from "@/design-system/Gallery"
import { Image } from "@/design-system/Image"
import { loadNextToLoadMore } from "@/design-system/ScrollingPaginator"
import { useTranslate } from "@/hooks/useTranslate"
import { FeaturedAddEditModalControllerQuery } from "@/lib/graphql/__generated__/FeaturedAddEditModalControllerQuery.graphql"
import { themeVariant } from "@/styles/styleUtils"
import {
  FeaturedAddEditModalState,
  FEATURED_ITEM_LIMIT,
} from "./FeaturedAddEditModal.react"
import { QueriedFeaturedAsset } from "./FeaturedAddEditModalController.react"

export function FeaturedAddEditModalSearchResults({
  assets,
  hasNext,
  isLoadingNext,
  loadNext,
  modalState,
  onSelectItem,
}: {
  assets: QueriedFeaturedAsset[]
  hasNext: boolean
  isLoadingNext: boolean
  loadNext: LoadMoreFn<FeaturedAddEditModalControllerQuery>
  modalState: FeaturedAddEditModalState
  onSelectItem: (item: QueriedFeaturedAsset) => void
}) {
  return (
    <Block flexGrow={1}>
      <Gallery
        getKey={asset => asset.relayId}
        gridGap={16}
        isLoading={isLoadingNext}
        itemHeightEstimate={300}
        itemMinWidth={200}
        items={assets}
        pagination={{
          page: {
            hasMore: () => hasNext,
            loadMore: count => loadNextToLoadMore({ loadNext, count }),
            isLoading: () => isLoadingNext,
          },
          size: 20,
        }}
        renderItem={item => (
          <FeaturedAddEditModalItem
            asset={item.data}
            isDragging={false}
            order={modalState.order.findIndex(
              x => x.relayId === item.data.relayId,
            )}
            shelfIsFull={modalState.order.length >= FEATURED_ITEM_LIMIT}
            visible
            width={item.width}
            onClick={() => onSelectItem(item.data)}
          />
        )}
        variant="flex-wrap"
      />
    </Block>
  )
}

export function FeaturedAddEditModalItem({
  asset,
  isDragging,
  order,
  reviewMode,
  shelfIsFull,
  visible,
  width,
  onClick,
}: {
  asset: QueriedFeaturedAsset
  isDragging: boolean
  order: number
  reviewMode?: boolean
  shelfIsFull: boolean
  visible: boolean
  width: number
  onClick: () => void
}) {
  const t = useTranslate("phoenix")
  const theme = useTheme()
  const selected = order >= 0
  const disabled = shelfIsFull && !selected
  return (
    <ItemContainer
      className={classNames("relative", visible ? "opacity-100" : "opacity-0")}
    >
      <ItemBlock
        aria-label={asset.name || t("featured.shelfItemLabel", "Shelf item")}
        className={reviewMode || disabled ? undefined : "cursor-pointer"}
        disabled={disabled}
        role="button"
        tabIndex={0}
        width={width}
        onClick={() => !reviewMode && onClick()}
        onKeyDown={e => !reviewMode && e.key === "Enter" && onClick()}
      >
        <Block className="relative">
          <Block>
            <StyledImage
              alt={asset.name ?? t("featured.shelfItemAlt", "Shelf item")}
              disabled={disabled}
              height={width}
              objectFit="cover"
              src={asset.displayImageUrl ?? ""}
              width={width}
            />
          </Block>
          <Block marginBottom="12px" marginTop="6px" marginX="10px">
            <Block color={theme.colors.text.primary}>
              <Text
                asChild
                className="line-clamp-1 w-full text-ellipsis whitespace-normal"
                color="primary"
                size="tiny"
                weight="semibold"
              >
                <div>{asset.name || " "}</div>
              </Text>
              <Flex>
                <StyledOverflowContainer>
                  <Text asChild color="primary" size="tiny" weight="semibold">
                    <p>{asset.collection.name || " "}</p>
                  </Text>
                </StyledOverflowContainer>
                {asset.collection.isVerified && (
                  <Flex aria-hidden="true">
                    <VerificationIcon
                      showTooltip={false}
                      size="tiny"
                      verificationStatus="VERIFIED"
                    />
                  </Flex>
                )}
              </Flex>
            </Block>
          </Block>
          {!reviewMode && (!shelfIsFull || !disabled) && (
            <SelectedDisplay disabled={disabled} selected={selected}>
              {selected && (
                <Text.Body
                  className="text-white"
                  size="small"
                  weight="semibold"
                >
                  {order + 1}
                </Text.Body>
              )}
            </SelectedDisplay>
          )}
          {reviewMode && !isDragging && (
            <ItemRemoveTarget
              onPointerDown={e => {
                e.stopPropagation()
                onClick()
              }}
            >
              <Icon
                aria-label={t("featured.removeLabel", "Remove")}
                size={24}
                value="close"
                weight={600}
              />
            </ItemRemoveTarget>
          )}
        </Block>
      </ItemBlock>
    </ItemContainer>
  )
}

export function FeaturedAddEditModalLoadingState() {
  return (
    <Flex
      alignItems="center"
      className="w-full"
      height="100%"
      justifyContent="center"
    >
      <Spinner />
    </Flex>
  )
}

const StyledImage = styled(Image)<{ disabled: boolean }>`
  opacity: ${props => (props.disabled ? 0.3 : undefined)};
  transition: opacity 0.25s ease-out;
`

const SelectedDisplay = styled(VerticalAligned)<{
  selected: boolean
  disabled: boolean
}>`
  align-items: center;
  background: ${props =>
    props.selected ? props.theme.colors.primary : props.theme.colors.white};
  border: 1px solid
    ${props =>
      props.selected ? props.theme.colors.white : props.theme.colors.primary};
  border-radius: ${props => props.theme.borderRadius.circle};
  color: ${props => props.theme.colors.white};
  font-weight: 700;
  height: 30.5px;
  opacity: ${props => (props.disabled ? "40%" : undefined)};
  position: absolute;
  right: 8px;
  top: 8px;
  width: 30.5px;
`

const ItemBlock = styled(Block)<{ disabled: boolean }>`
  overflow: hidden;

  background-color: ${props =>
    props.theme.colors.components.elevation.level1.regular.background};
  border-radius: ${props => props.theme.borderRadius.default};
  box-shadow: ${props =>
    props.theme.colors.components.elevation.level1.regular.shadow};

  &:hover {
    ${props =>
      themeVariant({
        variants: {
          dark: {
            backgroundColor: props.theme.colors.ash,
          },
        },
      })};
    box-shadow: ${props =>
      props.disabled
        ? undefined
        : props.theme.colors.components.elevation.level1.regular.shadow};
    transition: 0.1s;
  }

  &:focus {
    ${props =>
      themeVariant({
        variants: {
          dark: {
            backgroundColor: props.theme.colors.oil,
          },
        },
      })}
  }
`

const StyledOverflowContainer = styled(OverflowContainer)`
  color: ${props => props.theme.colors.text.primary};
  width: auto;
`

const ItemRemoveTarget = styled(Flex)`
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 50%;
  color: transparent;
  font-weight: 700;
  height: 32px;
  position: absolute;
  right: 8px;
  top: 8px;
  width: 32px;
  cursor: pointer;
`

// When hovering anywhere in the item container, the remove target should be visible
const ItemContainer = styled(Block)`
  user-select: none;
  &:hover ${ItemRemoveTarget} {
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.charcoal};
    transition: all 0.25s ease-in-out;
  }
`
