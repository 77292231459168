/**
 * @generated SignedSource<<1c1cff5d0a6ad316df940b25734eddbc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeaturedShelfAssets_data$data = {
  readonly shelfAssets: ReadonlyArray<{
    readonly asset: {
      readonly displayImageUrl: string | null;
      readonly id: string;
      readonly name: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"asset_url">;
    } | null;
    readonly displayOrder: number;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"LegacyAssetCard_data">;
  }> | null;
  readonly " $fragmentType": "FeaturedShelfAssets_data";
};
export type FeaturedShelfAssets_data$key = {
  readonly " $data"?: FeaturedShelfAssets_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeaturedShelfAssets_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showContextMenu"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeaturedShelfAssets_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountShelfAssetType",
      "kind": "LinkedField",
      "name": "shelfAssets",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayOrder",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetType",
          "kind": "LinkedField",
          "name": "asset",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayImageUrl",
              "storageKey": null
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "asset_url",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetContractType",
                  "kind": "LinkedField",
                  "name": "assetContract",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "address",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tokenId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ChainType",
                  "kind": "LinkedField",
                  "name": "chain",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "identifier",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        },
        {
          "args": [
            {
              "kind": "Variable",
              "name": "showContextMenu",
              "variableName": "showContextMenu"
            }
          ],
          "kind": "FragmentSpread",
          "name": "LegacyAssetCard_data"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccountShelfType",
  "abstractKey": null
};
})();

(node as any).hash = "5dc7149716bd3adf95c31016686dd485";

export default node;
