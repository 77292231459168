/**
 * @generated SignedSource<<e8a4160946659e555d27ee45d5d1e182>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeaturedShelfAssetsCount_shelf$data = {
  readonly shelfAssets: ReadonlyArray<{
    readonly __typename: "AccountShelfAssetType";
  }> | null;
  readonly " $fragmentType": "FeaturedShelfAssetsCount_shelf";
};
export type FeaturedShelfAssetsCount_shelf$key = {
  readonly " $data"?: FeaturedShelfAssetsCount_shelf$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeaturedShelfAssetsCount_shelf">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "FeaturedShelfAssetsCount_shelf"
};

(node as any).hash = "89b9b8baee3c4b02a5b6ac0a16d9f8f6";

export default node;
