/**
 * @generated SignedSource<<806da22ab62689b7c7685e6eed88da2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FeaturedShelfDeleteShelfMutation$variables = {
  shelfId: string;
};
export type FeaturedShelfDeleteShelfMutation$data = {
  readonly accounts: {
    readonly deleteAccountShelf: number;
  };
};
export type FeaturedShelfDeleteShelfMutation = {
  response: FeaturedShelfDeleteShelfMutation$data;
  variables: FeaturedShelfDeleteShelfMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shelfId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AccountMutationType",
    "kind": "LinkedField",
    "name": "accounts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "accountShelf",
            "variableName": "shelfId"
          }
        ],
        "kind": "ScalarField",
        "name": "deleteAccountShelf",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeaturedShelfDeleteShelfMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeaturedShelfDeleteShelfMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "30220deeb2e7a261d2596c95be50d4b9",
    "id": null,
    "metadata": {},
    "name": "FeaturedShelfDeleteShelfMutation",
    "operationKind": "mutation",
    "text": "mutation FeaturedShelfDeleteShelfMutation(\n  $shelfId: AccountShelfScalar!\n) {\n  accounts {\n    deleteAccountShelf(accountShelf: $shelfId)\n  }\n}\n"
  }
};
})();

(node as any).hash = "2bcdc983dc956fba7b29d322f95a74d7";

export default node;
